import React from "react";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";
import Section from "./Core/Section";
import Container from "./Core/Container";

const Content = styled.div`
  border: 1px solid var(--borderGrey);
  border-radius: .25rem;
  padding: 1.25rem;

  h2 {
    font-size: 1.25rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    column-count: 2;
    width: 100%;
    display: grid; 
    grid-template-columns: 1fr 1fr;
  }

  li {
    position: relative;
    font-size: .875rem;
    margin-bottom: .75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    display: inline;
    color: var(--primary);
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }

  }
`;


const NewQuickNavigation = (props) => {

    return (
      <>
      {props.links && props.links.length > 0 &&
        <Section>
          <Container>  
                  <Content>
                      {props.heading && <h2>{props.heading}</h2>}
                      <ul>
                        {props.links.map(link => {
                          return <li>
                            <a href={link.url?.current}>{link.title}</a>
                          </li>
                        })}
                      </ul>
                  </Content>
          </Container>
        </Section>
      }
      </>
    );
};

export default NewQuickNavigation;